<template>
  <v-container>

    <v-layout row wrap pr-2 mt-1>


      <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2>

        <card name="عدد الحجوزات الكلي" icon="fas fa-layer-group" text_color="#53D3F8" icon_color="#035aa6"
          :count="getAllCounts()" :loading="laoding_card[0]"></card>
      </v-flex>
      <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2>

        <card :name="'المقبوله'" text_color="#6CD6A1" :color="green" :count="counts.ownerConfirmed" icon_color="green"
          icon="fas fa-check">
        </card>

      </v-flex>

      <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2>

        <card :name="'المرفوضه'" icon="fas fa-calendar-times" text_color="#F56D7E" icon_color="red"
          :count="counts.ownerReject"></card>

      </v-flex>


      <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2>

        <card :name="'المعلقه'" icon="fas fa-business-time" text_color="#f29c2b" icon_color="#f07821"
          :count="counts.binding"></card>
      </v-flex>





    </v-layout>


    <v-layout row wrap mt-1 pr-2>

      <v-card class="dash_rate_card" style="width:100%;height:500px;border-radius:10px">
        <v-card width="200" outlined style="background-color:#035aa6;margin-bottom: 22px;margin-top:20px;">
          <h1 class="dash_part_rate_title" style="color:#fff;text-align:center">مواعيد الحجوزات</h1>
        </v-card>




        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat>


                <v-btn pr-3 outlined class="btn_today" color="grey darken-2" @click="setToday"
                  style="font-size: 20px !important;  color: green !important;font-weight: bold;font-family: 'Material Design Icons';">
                  اليوم
                </v-btn>


                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon large>
                     mdi-chevron-right
                  </v-icon>
                </v-btn>

                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon large>
                    mdi-chevron-left
                   
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>

                <!-- 
                <v-chip-group mandatory active-class="primary--text">
                  <v-chip v-for="tag in lastdays" :key="tag" @click="setToday('2020-10-24')">
                    {{ tag.name }}
                  </v-chip>
                </v-chip-group> -->



                <!-- <v-spacer></v-spacer> -->
              </v-toolbar>


            </v-sheet>
            <v-sheet height="600">
              <v-calendar ref="calendar" v-model="focus" color="primary" type="category" locale="ar-iq"
                category-show-all :categories="categories" :events="events" :event-color="getEventColor"
                @change="fetchEvents">

              </v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card>



      <!-- <v-flex md6 xs12 style="margin-bottom:1%" pa-1>

       
        <v-card class="dash_rate_card">

          <p class="dash_part_rate_title">احصائيه حالات الحجز</p>
          <v-divider class="mb-3"></v-divider>






          <div id="chart">
            <apexchart type=pie class="circle_chart" :options="chartOptions" :series="seriess" />
          </div>

        </v-card>
      </v-flex> -->


      <!-- 
      <v-flex md6 sm12 style="padding-left:0%" pa-1 pr-4>
        <v-card style="width:100%;height:500px;border-radius:10px">
          <v-row>
            <p class="dash_part_rate_title">احصائيه سنويه للحجوزات</p>

          </v-row>
          <v-divider class="mb-3"></v-divider>

          <div style="padding-right:10%;padding-left:5%">
            <apexchart type="bar" :options="options" :series="series"></apexchart>
          </div>
        </v-card>

      </v-flex> -->
    </v-layout>





  </v-container>
</template>

<style>
.v-event
{
    display:none;
}
  .v-event-timed {
    font-size: 17px !important;
    /* padding-top:10px; */
    font-weight: bold;
    padding-right: 13px;

  }

  .v-calendar-category__category
  {
    display:none;
  }

  .v-calendar-daily__interval-text {
    font-weight: bold;
    font-size: 17px;
  }
</style>
<script>
  import card from './Card.vue';

  //import Donut from 'vue-css-donut-chart'
  //import VueApexCharts from 'vue-apexcharts'

  export default {


    data() {
      return {

        focus: '',
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        categories: ['John Smith'],


        counts: [

        ],
        dialog: false,
        search: '',
        page: 1,
        pageCount: 2,
        loading: false,
        id: -1,
        item_selected: {},
        loding_accept: false,
        tags: [
          'Work',
          'Home Improvement',
          'Vacation',
          'Food',
          'Drawers',
          'Shopping',
          'Art',
          'Tech',
          'Creative Writing',
        ],
        loding_cancel: false,

        reservationCount: '',

        date_select: '',
        menu1: false,
        counts_all: '',
        current_page: 0,
        last_page: 0,
        chartOptions: {
          labels: ['تم الانجاز', 'مرفوضه', 'المقبوله', 'المعلقه'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
        },
        seriess: [33, 10, 40, 55],

        options: {
          chart: {
            id: 'vuechart-example'
          },
          xaxis: {
            categories: ['حزيران', 'تموز', 'آب', 'أيلول']
          }
        },

        series: [{
          name: 'series-1',
          data: [153, 200, 100, 400]
        }],
        filters: {
          first_date: '',
          second_date: ''
        },
        reservation: [],
        lastdays: [

        ],
        sections: [

        ],
        days: ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"],
        getmore: false,
        error_msg: false,
        laoding_card: [false, false, false, false]

      }
    },

    methods: {

      getLastDays() {

        var goBackDays = 7;

        var today = new Date();

        var newDate = new Date(today.setDate(today.getDate()));
        this.lastdays.push({
            name: 'اليوم',
            date: newDate.getFullYear() + '-' + newDate.getMonth() + '-' + newDate.getDate()
          }


        );


        for (var i = 0; i < goBackDays; i++) {
          newDate = new Date(today.setDate(today.getDate() + 1));

          this.lastdays.push({
              name: this.days[newDate.getDay()],
              date: newDate.getFullYear() + '-' + (1 + newDate.getMonth()) + '-' + newDate.getDate()
            }


          );
        }



      },

      getEventColor(event) {
        return event.color
      },
      setToday() {

        // var today = new Date();
        // var dd = String(today.getDate()).padStart(2, '0');
        // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        // var yyyy = today.getFullYear();
        // today = yyyy + '-' + mm + '-' + dd;

        // this.filters.first_date = today;
        // this.filters.second_date = today;
        // this.Search(date);
        this.focus = ''
      },


      prev() {
        this.$refs.calendar.prev()
      },
      next() {
        this.$refs.calendar.next()
      },


      fetchEvents({
        start,
        end
      })


      {

        this.Search(start, end)



      },



      rnd(a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },



      getcounts() {



        const axios = require('axios');
        axios({
          method: 'get',
          url: '/reservation/owner/dashboard',
          headers: {

          }

        }).then(response => {
          this.counts = response.data.data;
          this.counts_all = response.data.item_all;



        }).catch(error => {
          error
          // this.$swal('خطاء', "خطاء بالاتصال", 'error')

        }).finally(d => {
          d
        });

      },

      getAllCounts() {

        return this.counts.ownerConfirmed + this.counts.ownerReject + this.counts.binding > -1 ? this.counts
          .ownerConfirmed + this.counts.ownerReject + this.counts.binding : "";
      },

      deleteItem(item) {
        //  const index = this.items.indexOf(item)
        this.cancelReservation(item);




      },

      Search(start, end) {
        end,

        this.filters.first_date = start.date;
        this.filters.second_date = start.date;
        var date = this.filters.first_date + '_' + this.filters.second_date;
        var url = '/reservation/owner/search?filter[BetweenDate]=' + date + '&sort=-id&page=1';
        this.$http({
          method: 'get',
          url: url,


        }).then(response => {
          this.loading = false;
          this.reservation = response.data.data;
          const events = []
          for (let i = 0; i < this.reservation.length; i++) {

            const min = new Date(`${start.date}T` + this.reservation[i].reservation_from_time)
            const max = new Date(`${start.date}T` + this.reservation[i].reservation_to_time)

            const allDay = this.rnd(0, 3) === 0
            const firstTimestamp = min.getTime();
            const first = new Date(firstTimestamp)
            const secondTimestamp = max.getTime();
            const second = new Date(secondTimestamp)

            events.push({
              name: this.reservation[i].user.full_name,
              start: first,
              end: second,
              color: this.reservation[i].status.status_color,
              timed: !allDay,
              category: this.categories[0],
            })
          }

          this.events = events


        }).catch(rr => {
            rr
            //this.$swal('خطاء', "خطاء بالاتصال", 'error')
          }

        ).finally(


        );


      },


    },
    mounted() {
   //   alert('hello');
        this.user_info = this.$cookies.get('admin_info');

      this.getcounts();
      this.getLastDays();
      //this.setToday();
      this.fetchEvents();
     // this.Search('2020-10-21','2020-10-21')
      this.$refs.calendar.checkChange()
    


      

    },

    created() {


    },

    components: {


      card,
      //apexchart: VueApexCharts,

    }

  }
</script>